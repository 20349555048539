import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import {
  ErrorDisplay,
  getFieldClassName,
} from "components/GeneralComponents/Utils";
import useSignUpHook from "./useSignUpHook";
import CommonForm from "views/CommonForm";
import "./SignUp.scss";
import FaEye from "assets/img/eye-password-show.svg";
import FaEyeSlash from "assets/img/eye-password-hide.svg";
import { Row, Col } from "antd";

function SignUp() {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    signin,
    handleLinkClick,
  } = useSignUpHook();

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown => !passwordShown);
  };

  return (
    <CommonForm>
      <div className="signup-form-wrap">
        <h2 className="form-title">Sign Up</h2>
        <Form
          className="sign-up-form common-form-content"
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <Row gutter={[20,20]}>
              <Col sm={12} xs={24}>
                <Label className="form-label" for="first_name">
                  First Name
                </Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  className={getFieldClassName("first_name", errors, touched)}
                />
                <ErrorDisplay
                  name="first_name"
                  errors={errors}
                  touched={touched}
                />
              </Col>
              <Col sm={12} xs={24}>
                <Label className="form-label" for="last_name">
                  Last Name
                </Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  className={getFieldClassName("last_name", errors, touched)}
                />
                <ErrorDisplay
                  name="last_name"
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label className="form-label" for="email">
              Email
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={getFieldClassName("email", errors, touched)}
            />
            <ErrorDisplay name="email" errors={errors} touched={touched} />
          </FormGroup>
          <FormGroup>
            <Label className="form-label" for="password">
              Password
            </Label>
            <div className="password-input-container">
            <Input
              type={passwordShown ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter your password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={getFieldClassName("password", errors, touched)}
            />
            <span className="password-toggle-icon" onClick={togglePasswordVisiblity}>
                {passwordShown ? <img src={FaEyeSlash} alt="Google sign-in" /> : <img src={FaEye} alt="Google sign-in" />}
              </span>
            </div>
            <ErrorDisplay name="password" errors={errors} touched={touched} />
          </FormGroup>
          <div className="create-account-link">
            <Input
              type="checkbox"
              name="terms_and_conditions"
              id="terms_and_conditions"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.terms_and_conditions}
              className={getFieldClassName(
                "terms_and_conditions",
                errors,
                touched
              )}
            />
            <Label for="terms_and_conditions">
              By creating an account, you are agree to our{" "}
              <Link onClick={handleLinkClick} className="terms-links">
                Terms & Conditions
              </Link>
            </Label>
            <ErrorDisplay
              name="terms_and_conditions"
              errors={errors}
              touched={touched}
            />
          </div>
          <Button
            className="common-submit-btn"
            type="submit"
            block
            disabled={!values?.terms_and_conditions}
          >
            Sign Up
          </Button>
          <div className="form-footer-account-link">
            Already have an account?
            <Link to={signin} className="footer-redirect-link" type="Sign Up">
              Sign In
            </Link>
          </div>
        </Form>
      </div>
    </CommonForm>
  );
}

export default SignUp;