import React, { useLayoutEffect, useState, useRef } from "react";
import { Card, Avatar } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { startCase } from "lodash";
import dayjs from "dayjs";

const LocumProfileCard = ({ locumUserData }) => {
  const {
    first_name,
    last_name,
    profile_photo,
    bio,
    createdAt,
    ahpra,
    language,
    location,
  } = locumUserData;
  const priLanguage = language[0]?.language;

  const locationStr =
    location?.locationName && location?.address
      ? `${location?.locationName}, ${location?.address}`
      : location?.locationName
      ? location?.locationName
      : location?.address
      ? location?.address
      : "";

  const [contentHeight, setContentHeight] = useState(240);
  const locumHeaderRef = useRef(null);

  const locumProfileDescHeight = () => {
    // content spacing
    const contentSpace = document.querySelector('.content');
    const contentSpaceMargin = contentSpace ? parseFloat(getComputedStyle(contentSpace).marginTop) : 0;
    const contentSpacePadding = contentSpace ? parseFloat(getComputedStyle(contentSpace).paddingBottom) : 0;
    const contentSpaceTotal = contentSpaceMargin + contentSpacePadding;

    // page header
    const pageTitleSpace = document.querySelector('.page-main-title');
    const pageTitleMargin = pageTitleSpace ? parseFloat(getComputedStyle(pageTitleSpace).marginBottom) : 0;
    const pageTitleHeight = pageTitleSpace ? pageTitleSpace.offsetHeight : 0;
    const pageTitle = pageTitleMargin + pageTitleHeight;

    // locum card body
    const locumInfo = document.querySelector('.locum-info-wrap');
    const locumInfoPadTop = locumInfo ? parseFloat(getComputedStyle(locumInfo).paddingTop) : 0;
    const locumInfoPadBot = locumInfo ? parseFloat(getComputedStyle(locumInfo).paddingBottom) : 0;
    const locumInfoSpace = locumInfoPadTop + locumInfoPadBot;

    // locum card header
    const locumBioHead = document.querySelector('.locum-bio-head');
    const locumBioHeadHeight = locumBioHead ? locumBioHead.offsetHeight : 0;
    const locumBioHeadSpace = locumBioHeadHeight;

    // card spacing
    const cardSpace = document.querySelector('.ant-card-body');
    const cardSpacePaddingTop = cardSpace ? parseFloat(getComputedStyle(cardSpace).paddingTop) : 0;
    const cardSpacePaddingBottom = cardSpace ? parseFloat(getComputedStyle(cardSpace).paddingBottom) : 0;
    const cardSpacePadding = cardSpacePaddingTop + cardSpacePaddingBottom;

    // locum profile header
    const headerHeight = locumHeaderRef.current ? locumHeaderRef.current.getBoundingClientRect().height : 0;
    const titleMarginBottom = locumHeaderRef.current ? parseFloat(getComputedStyle(locumHeaderRef.current).marginBottom) : 0;
    const locumProfileHeader = headerHeight + titleMarginBottom;

    // Drawer specific adjustments
    const drawerContent = document.querySelector('.ant-drawer-content');
    let drawerAdjustments = 0;

    // Adjust height if drawer is present
    if (drawerContent) {
      const drawerHeader = document.querySelector('.ant-drawer-header');
      const drawerBody = document.querySelector('.ant-drawer-body');

      const drawerHeaderSpace = drawerHeader ? drawerHeader.offsetHeight : 0;
      // const drawerHeaderPadTop = drawerHeader ? parseFloat(getComputedStyle(drawerHeader).paddingTop) : 0;
      // const drawerHeaderPadBot = drawerHeader ? parseFloat(getComputedStyle(drawerHeader).paddingBottom) : 0;
      const drawerBodyPadTop = drawerBody ? parseFloat(getComputedStyle(drawerBody).paddingTop) : 0;
      const drawerBodyPadBot = drawerBody ? parseFloat(getComputedStyle(drawerBody).paddingBottom) : 0;

      drawerAdjustments = drawerHeaderSpace + drawerBodyPadTop + drawerBodyPadBot - contentSpaceTotal - pageTitle;
    }

    // calculate available height
    let availableHeight = window.innerHeight - contentSpaceTotal - pageTitle - locumInfoSpace - locumBioHeadSpace - cardSpacePadding - locumProfileHeader - drawerAdjustments;

    setContentHeight(availableHeight);
  };

  useLayoutEffect(() => {
    locumProfileDescHeight();
    window.addEventListener('resize', locumProfileDescHeight);

    return () => {
      window.removeEventListener('resize', locumProfileDescHeight);
    };
  }, []);

  return (
    <Card>
      <div ref={locumHeaderRef} className="locum-profile-header-wrap locum-section-seperate">
        <div className="locum-profile-header-left">
          <span className="locum-profile-img">
            <Avatar src={profile_photo} />
          </span>
          <div className="locum-profile-details">
            <h6 className="locum-profile-user-name">
              {`${startCase(first_name)} ${startCase(last_name)}`}
            </h6>
            <span className="locum-profile-location">
              {locationStr && (
                <>
                  <EnvironmentFilled />
                  <span className="spec-location">{locationStr}</span>
                </>
              )}
            </span>
          </div>
        </div>
        <div className="locum-profile-header-right">
          <span className="locum-profile-date-label">Locum since:</span>
          <span className="locum-profile-date">
            {dayjs(createdAt).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div className="locum-profile-desc-details" style={{ height: `${contentHeight}px`}}>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Bio</h6>
          {bio}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Qualifications</h6>
          Masters in Optomistics
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">APHRA #</h6>
          {ahpra}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Expiry</h6>
          I'm not going to fail, a valuable lesson has been learned
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Primary Language</h6>
          {priLanguage || "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Secondary Language</h6>
          {language.slice(1).map((lang, i) => (
            <React.Fragment key={`${lang}-${i}`}>
              <span>{lang?.language}</span>
              {i < language.length - 2 && ", "}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default LocumProfileCard;
