/*eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { startCase } from "lodash";
import { get_api } from "api";
import { post_api } from "api";

const useShiftRequestsListHook = () => {
  const [shiftData, setShiftData] = useState([]);
  const [requestedUsersList, setRequestedUsersList] = useState([]);
  const [locumUserData, setLocumUserData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [requestedUserLoading, setRequestedUserLoading] = useState(false);
  const [locumUserLoading, setLocumUserLoading] = useState(false);
  const [acceptLoadingIds, setAcceptLoadingIds] = useState([]);
  const [declineLoadingIds, setDeclineLoadingIds] = useState([]);

  const [open, setOpen] = useState(false);

  const getAllPostShifts = async () => {
    try {
      setIsLoading(true);
      const res = await get_api("post-shifts/all");
      setShiftData(res?.data?.postShifts);
      setIsLoading(false);
      return true;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPostShifts();
  }, []);

  const getShiftRequests = async (id) => {
    try {
      setRequestedUserLoading(true);
      const res = await get_api(`post-shifts/requested/${id}`);
      setRequestedUsersList(res?.data?.requestedUsers);
      setLocumUserData({});
      setRequestedUserLoading(false);
      return true;
    } catch (err) {
      toast.error(err.message);
      setRequestedUserLoading(false);
    }
  };

  const getLocumUserById = async (id) => {
    try {
      setLocumUserLoading(true);
      const res = await get_api(`profile/${id}/locum`);
      setLocumUserData(res?.data?.locumUsersProfile);
      setLocumUserLoading(false);
      return true;
    } catch (err) {
      toast.error(err.message);
      setLocumUserLoading(false);
    }
  };

  const manageRequest = async (manageObj) => {
    try {
      if (manageObj.status === 'accepted') {
        setAcceptLoadingIds((prev) => [...prev, manageObj.locumId]);
      } else {
        setDeclineLoadingIds((prev) => [...prev, manageObj.locumId]);
      }
  
      const res = await post_api(`my-shift/manage`, manageObj);
      toast.success(res.message);
      getAllPostShifts();
      // setRequestedUsersList([])

      const resRequest = await get_api(`post-shifts/requested/${manageObj?.shiftId}`);
      manageObj.status === 'accepted' ? setRequestedUsersList([]) : setRequestedUsersList(resRequest?.data?.requestedUsers);

      // getShiftRequests(manageObj?.shiftId);
      // getLocumUserById(manageObj?.locumId);
      // setLocumUserData(res?.data?.locumUsersProfile);
      if (manageObj.status === 'accepted') {
        setAcceptLoadingIds((prev) => prev.filter((id) => id !== manageObj.locumId));
      } else {
        setDeclineLoadingIds((prev) => prev.filter((id) => id !== manageObj.locumId));
      }
      if (manageObj.status === 'accepted') {
        // Remove the accepted locumId from the requestedUsersList
        setRequestedUsersList((prevList) => 
          prevList.filter((user) => user._id !== manageObj.locumId)
        );
  
        // Loop through the remaining users in the list and send decline status
        for (const user of requestedUsersList) {
          if (user._id !== manageObj.locumId) {
            const declineObj = { ...manageObj, locumId: user._id, status: 'declined' };
            await post_api('my-shift/manage', declineObj);
          }
        }
      }
      return true;
    } catch (err) {
      toast.error(err.message);
      if (manageObj.status === 'accepted') {
        setAcceptLoadingIds((prev) => prev.filter((id) => id !== manageObj.locumId));
      } else {
        setDeclineLoadingIds((prev) => prev.filter((id) => id !== manageObj.locumId));
      }
    }
  };

  const shiftsColumns = [
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div className="fields-wrapper"
          key={record._id}
          onClick={() => getShiftRequests(record?._id)}
          style={{ cursor: "pointer" }}
        >
          <p>{dayjs(text).locale("en").format("ddd DD MMM YYYY")}</p>
          <p>
            {record.start_time} - {record.end_time}
          </p>
        </div>
      ),
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => <div key={record._id}>{text}</div>,
      align: "center",
    },
    {
      title: "Rate",
      dataIndex: "total_rate",
      key: "total_rate",
      render: (text, record) => {
        return <div key={record._id}>$ {text}</div>;
      },
      align: "center",
    },
    {
      title: "Total Requests",
      dataIndex: "requestedShiftsCount",
      key: "requestedShiftsCount",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return <div key={record._id}>{startCase(text)}</div>;
      },
      align: "right",
    },
  ];

  const requestsColumns = [
    {
      title: "Requests",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div
          className="request-wrap"
          key={record._id}
          onClick={() => {
            setOpen(true);
            getLocumUserById(record?._id);
          }}
          style={{ cursor: "pointer" }}
        >
          <h6 className="request-name">
            {`${startCase(text)} ${startCase(record?.last_name)}`}
          </h6>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size={[8, 0]} key={record._id}>
          {/* Accept Button */}
          <Button
            style={{border:'none'}}
            icon={acceptLoadingIds.includes(record?._id) ? <Spin size="small" /> : <i className="fas fa-check" />}
            disabled={declineLoadingIds.includes(record?._id) || acceptLoadingIds.includes(record?._id)}
            onClick={() =>
              manageRequest({
                status: "accepted",
                shiftId: record?.postShiftId,
                locumId: record?._id,
              }, 'accepted')
            }
          >
          </Button>
    
          {/* Decline Button */}
          <Button
            style={{border:'none'}}
            icon={declineLoadingIds.includes(record?._id) ? <Spin size="small" /> : <i className="fas fa-times" />}
            disabled={acceptLoadingIds.includes(record?._id) || declineLoadingIds.includes(record?._id)}
            onClick={() =>
              manageRequest({
                status: "declined",
                shiftId: record?.postShiftId,
                locumId: record?._id,
              }, 'declined')
            }
          >
          </Button>
        </Space>
      ),
      align: "right",
    },
    
    
  ];

  return {
    shiftsColumns,
    shiftData,
    isLoading,
    requestsColumns,
    requestedUsersList,
    requestedUserLoading,
    locumUserData,
    locumUserLoading,
    open,
    setOpen,
    getShiftRequests
  };
};

export default useShiftRequestsListHook;
