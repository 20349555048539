/*eslint-disable no-unused-vars */
/*eslint-disable array-callback-return */
import { Radio, Select, Space, Upload } from "antd";
import { get_api } from "api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Label } from "reactstrap";
import Map from "views/Map";
import { ReactComponent as CustomArrowIcon } from "../../assets/img/dropdown-icon.svg";
import deleteIcon from "../../assets/img/delete-icon.svg";
import uploadIcon from "../../assets/img/upload-icon.svg";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post_api } from "api";
import { getFirebaseUser } from "firebaseConfig";
import { put_api } from "api";

const { Option } = Select;

const useCompanyProfileSetupHook = () => {
  const { setCompanyProfile, userData, companyProfile } = useAuth();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allSoftwares, setAllSoftwares] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);

  const canceledSubscription = companyProfile?.pricingPlan?.subscriptionStatus === 'canceled' //|| companyProfile?.pricingPlan?.subscriptionStatus === 'trialing'

  const [companyProfileObj, setCompanyProfileObj] = useState({
    pricingPlan: {
      plan: "year",
      discountCode: null,
    },
    companyName: companyProfile?.companyName || null,
    location: companyProfile?.location || {
      lat: -33.86882,
      long: 151.2093,
      address: "",
      locationName: "",
    },
    contact: companyProfile?.contact || {
      email: userData?.email,
      phone: "",
      website: "",
    },
    language: companyProfile?.language || ["", ""],
    equipment: companyProfile?.equipment || [],
    services: companyProfile?.services || [],
    software: companyProfile?.software || [],
    testingTime: companyProfile?.testingTime || "30-mins",
    isVaccinated: companyProfile?.isVaccinated || false,
    logo: "",
    additionalRequirements: companyProfile?.additionalRequirements || [""],
  });
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const {
    pricingPlan,
    companyName,
    location,
    contact,
    language,
    equipment,
    services,
    software,
    testingTime,
    isVaccinated,
    logo,
    additionalRequirements,
  } = companyProfileObj;

  const handleOpenPaymentForm = () => {
    setShowPaymentForm(true);
  };

  const handleClosePaymentForm = () => {
    setShowPaymentForm(false);
  };

  const handleComplete = () => {
    if (pricingPlan?.plan !== "free") {
      const getProductByType = allProducts.find(
        (ap) => ap?.interval === pricingPlan?.plan
      );
      setSelectedProduct(getProductByType);
      handleOpenPaymentForm();
    } else {
      createCompanyProfile();
    }
  };

  const createCompanyProfile = async () => {
    try {
      const formData = new FormData();
      // Object.entries(pricingPlan).forEach(([key, value]) => {
      //   formData.append(`pricingPlan[${key}]`, value);
      // });
      formData.append("companyName", companyName);
      Object.entries(location).forEach(([key, value]) => {
        formData.append(`location[${key}]`, value);
      });
      Object.entries(contact).forEach(([key, value]) => {
        formData.append(`contact[${key}]`, value);
      });

      const removeEmptyLang = language.filter((lang) => lang !== "");
      removeEmptyLang.forEach((val, index) => {
        formData.append(`language[${index}]`, val);
      });
      equipment.forEach((val, index) => {
        formData.append(`equipment[${index}]`, val);
      });
      services.forEach((val, index) => {
        formData.append(`services[${index}]`, val);
      });
      software.forEach((val, index) => {
        formData.append(`software[${index}]`, val);
      });

      const removeEmptyAR = additionalRequirements.filter((adr) => adr !== "");
      removeEmptyAR.forEach((val, index) => {
        formData.append(`additionalRequirements[${index}]`, val);
      });
      formData.append("testingTime", testingTime);
      formData.append("isVaccinated", isVaccinated);

      if (logo) {
        formData.append("logo", logo);
      }

      let updatedData, updatedMessage
      if (canceledSubscription) {
        const { data, message } = await put_api("company-profile", formData);
        updatedData = data
        updatedMessage = message
      } else {
        const { data, message } = await post_api("company-profile", formData);
        updatedData = data
        updatedMessage = message
      }

      setCompanyProfile(JSON.stringify(updatedData.profile));
      localStorage.setItem("company_profile", JSON.stringify(updatedData.profile));
      localStorage.setItem("user", JSON.stringify(updatedData.user));
      await getFirebaseUser(updatedData.user, updatedData.profile);
      toast.success(updatedMessage);
      navigate("/dashboard");
      return updatedData;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getLanguages = async () => {
    try {
      const { data } = await get_api("language/get-all-languages");
      setCountryData(data.allLanguages);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getServices = async () => {
    try {
      const { data } = await get_api("service/all");
      setAllServices(data.allServices);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await get_api("software/all");
      setAllSoftwares(data.allSoftwares);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getEquipments = async () => {
    try {
      const { data } = await get_api("equipment/all");
      setAllEquipments(data.allEquipments);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getAllProducts = async () => {
    try {
      const { data } = await get_api("stripe/all-products");
      setAllProducts(data?.allStripeProducts);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    getLanguages();
    getAllProducts();
    getServices();
    getSoftwares();
    getEquipments();
  }, []);

  const handlePricingPlanChange = (e) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      pricingPlan: {
        ...prev.pricingPlan,
        discountCode: e.target.value,
      },
    }));
  };

  const handleCompanyChange = (e) => {
    const isCompanyNameValid = e.target.value.length <= 100;
    if (isCompanyNameValid) {
      setCompanyProfileObj((prev) => ({
        ...prev,
        companyName: e.target.value,
      }));
    }
  };

  const handleContactChange = (key, val) => {
    // Validations
    const isPhoneValid = key === "phone" && /^\d{0,10}$/.test(val);
    const isWebsiteOrEmailValid =
      ["website", "email"].includes(key) && val.length <= 100;

    if (isPhoneValid || isWebsiteOrEmailValid) {
      setCompanyProfileObj((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [key]: val,
        },
      }));
    }
  };

  const renderPricingPlan = () => {
    const monthlyProduct = allProducts.find((ap) => ap.interval === "month");
    const yearlyProduct = allProducts.find((ap) => ap.interval === "year");

    return (
      <>
        {/* <div className="price-discount-wrapper">
          <div className="discount-header"> */}
        <Label className="common-stepper-label">Pricing</Label>
        {/* <span className="discount-sub-header">
              Annual - $420 <br />
              $99 one time admin fee.
            </span> */}
        {/* </div> */}
        {/* <Input
            type="text"
            name="discountCode"
            id="discountCode"
            className="stepper-input-field"
            placeholder="Enter discount code"
            onChange={handlePricingPlanChange}
            value={pricingPlan?.discountCode || ""}
          /> */}
        {/* </div> */}
        <div className="discount-option-wrapper">
          <Radio.Group
            defaultValue="year"
            onChange={(e, v) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                pricingPlan: {
                  ...prev.pricingPlan,
                  plan: e.target.value,
                },
              }));
            }}
          >
            {/* <Radio.Button value="free">
              <div className="discount-opt">
                <span className="discount-type">Free</span>
                <h3 className="discount-price">$0<span>/month</span></h3>
                <span className="discount-trial">7 Days free trial</span>
                <span className="select-plan-label">Select Plan</span>
                <span className="separator"></span>
                <div className="discount-feature-listing">
                  <h6 className="benefits-title">Features & Benefits:</h6>
                  <ul className="feature-listing">
                    <li>Efficient Shift Filling</li>
                    <li>Analytics Insights</li>
                    <li>Comprehensive Dashboard</li>
                    <li>Dedicated Support</li>
                  </ul>
                </div>
                <span className="select-plan-label">Select Plan</span>
              </div>
            </Radio.Button> */}
            <Radio.Button value="month">
              <div className="discount-opt">
                <span className="discount-type">Monthly</span>
                <h3 className="discount-price">
                  ${monthlyProduct?.unit_amount}
                  <span>/month</span>
                </h3>
                <span className="discount-trial">7 Days free trial</span>
                <span className="select-plan-label">Select Plan</span>
                <span className="separator"></span>
                <div className="discount-feature-listing">
                  <h6 className="benefits-title">Features & Benefits:</h6>
                  <ul className="feature-listing">
                    <li>Efficient Shift Filling</li>
                    <li>Analytics Insights</li>
                    <li>Comprehensive Dashboard</li>
                    <li>Dedicated Support</li>
                  </ul>
                </div>
                <span className="select-plan-label">Select Plan</span>
              </div>
            </Radio.Button>

            <Radio.Button value="year">
              <div className="annual-discount-label">25% OFF</div>
              <div className="discount-opt">
                <span className="discount-type">Annual</span>
                <h3 className="discount-price">
                  ${yearlyProduct?.unit_amount}
                  <span>/month</span>
                </h3>
                <span className="discount-trial">7 Days free trial</span>
                <span className="select-plan-label">Select Plan</span>
                <span className="separator"></span>
                <div className="discount-feature-listing">
                  <h6 className="benefits-title">Features & Benefits:</h6>
                  <ul className="feature-listing">
                    <li>Efficient Shift Filling</li>
                    <li>Analytics Insights</li>
                    <li>Comprehensive Dashboard</li>
                    <li>Dedicated Support</li>
                  </ul>
                </div>
                <span className="select-plan-label">Select Plan</span>
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
      </>
    );
  };

  const renderCompany = () => {
    return (
      <>
        <Label className="common-stepper-label">Company Name</Label>
        <Input
          type="text"
          name="company_name"
          id="company_name"
          className="stepper-input-field"
          placeholder="Company/Store Name"
          onChange={handleCompanyChange}
          value={companyName || ""}
        />
      </>
    );
  };

  const handleSearch = async (value) => {
    setInputValue(value);
    if (value.length > 2) {
      try {
        const response = await get_api(`location/get-locations/${value}`);
        const locations = response.data.locations.predictions.map(
          (prediction) => ({
            label: prediction.description,
            value: prediction.place_id,
          })
        );
        setOptions(locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const getLocationDetails = async (placeId) => {
    try {
      const response = await get_api(
        `location/get-location-details/${placeId}`
      );
      const locationData = response.data.locationDetails;
      const cityName = locationData.address_components.find((component) =>
        component.types.includes("locality")
      )["long_name"];
      const stateName = locationData.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )["long_name"];

      setCompanyProfileObj((prev) => ({
        ...prev,
        location: {
          lat: locationData.geometry.location.lat,
          long: locationData.geometry.location.lng,
          address: `${cityName}, ${stateName}`,
          locationName: locationData.name,
        },
      }));
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleChangeMap = async (val, event) => {
    const selectedOption = options.find((option) => option.value === val);
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
    await getLocationDetails(val);
  };

  const handleClearSelection = () => {
    setInputValue("");
    setOptions([]);
  };

  const renderLocation = () => {
    return (
      <>
        <Label className="common-stepper-label">Location</Label>
        <div className="location-stepper">
          <Select
            showSearch
            className="common-dropdown common-antd-component"
            value={inputValue || undefined}
            placeholder="Search a location"
            onSearch={handleSearch}
            onChange={handleChangeMap}
            filterOption={false}
            notFoundContent={null}
            suffixIcon={
              inputValue && (
                <CloseCircleOutlined onClick={handleClearSelection} />
              )
            }
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <div className="location-map-wrapper">
            <Map lat={location.lat} long={location.long} />
          </div>
        </div>
      </>
    );
  };

  const renderContact = () => {
    return (
      <>
        <div className="common-field-group">
          <Label className="common-stepper-label">Email</Label>
          <Input
            type="text"
            name="email"
            id="email"
            className="stepper-input-field"
            placeholder="Enter email"
            onChange={(e) => handleContactChange("email", e.target.value)}
            value={contact?.email || ""}
          />
        </div>
        <div className="common-field-group">
          <Label className="common-stepper-label">Phone</Label>
          <Input
            type="number"
            name="phone"
            id="phone"
            className="stepper-input-field"
            placeholder="Enter phone"
            onChange={(e) => handleContactChange("phone", e.target.value)}
            value={contact?.phone || ""}
            maxLength={10}
          />
        </div>
        <div className="common-field-group">
          <Label className="common-stepper-label">Website</Label>
          <Input
            type="text"
            name="website"
            id="website"
            className="stepper-input-field"
            placeholder="Enter website"
            onChange={(e) => handleContactChange("website", e.target.value)}
            value={contact?.website || ""}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!companyProfile?.language) {
      const englishCountry = countryData.find(
        (country) => country.language === "English"
      );
      if (englishCountry) {
        setCompanyProfileObj((prev) => ({
          ...prev,
          language: [englishCountry._id, ""],
        }));
      }
    }
  }, [companyProfile?.language, countryData]);

  const handlePrimaryChange = (val) => {
    setCompanyProfileObj((prev) => {
      const secondaryLanguages = prev.language
        .slice(1)
        .filter((lang) => lang !== val);
      return {
        ...prev,
        language: [val, ...secondaryLanguages],
      };
    });
  };

  const handleSecondaryChange = (vals) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      language: [
        prev.language[0],
        ...vals.filter((val) => val !== prev.language[0]),
      ],
    }));
  };

  const handleAddSelectBox = () => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      language: [...prev.language, ""],
    }));
  };

  const handleRemoveLanguage = (id) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      language: prev.language.filter((lang) => lang !== id),
    }));
  };

  const renderLanguages = () => {
    return (
      <>
        <div className="language-selector-wrapper common-field-group">
          <label className="common-stepper-label">Primary Language</label>
          <Select
            className="common-dropdown common-antd-component"
            placeholder="Primary Language"
            optionFilterProp="children"
            onChange={handlePrimaryChange}
            value={language[0]}
            suffixIcon={<CustomArrowIcon />}
          >
            {countryData.map((country) => (
              <Option key={country._id} value={country._id}>
                <img
                  src={country.flag}
                  alt=""
                  style={{ width: "20px", marginRight: "8px" }}
                />
                {country.language}
              </Option>
            ))}
          </Select>
        </div>

        {language.slice(1).length > 0 && (
          <div className="language-selector-wrapper common-field-group">
            <label className="common-stepper-label">Secondary Language</label>
          </div>
        )}

        {language.slice(1).map((secondaryLanguage, index) => {
          return (
            <div
              key={index + 1}
              className="language-selector-wrapper common-field-group"
            >
              {language.slice(1).length > 1 && (
                <Button
                  className="delete-icon-wrap"
                  color="link"
                  onClick={() => handleRemoveLanguage(secondaryLanguage)}
                >
                  <img src={deleteIcon} alt="delete icon" />
                </Button>
              )}
              <Select
                className="common-dropdown common-antd-component"
                placeholder="Secondary Languages"
                optionFilterProp="children"
                onChange={(value) =>
                  handleSecondaryChange(
                    language
                      .slice(1)
                      .map((lang, idx) => (idx === index ? value : lang))
                  )
                }
                value={secondaryLanguage || undefined}
                suffixIcon={<CustomArrowIcon />}
              >
                {countryData.map((country) => (
                  <Option
                    key={country._id}
                    value={country._id}
                    disabled={
                      country._id === language[0] ||
                      language.slice(1).includes(country._id)
                    }
                  >
                    <img
                      src={country.flag}
                      alt=""
                      style={{ width: "20px", marginRight: "8px" }}
                    />
                    {country.language}
                  </Option>
                ))}
              </Select>
              
            </div>
          );
        })}

        <div className="add-other-field">
          <Button
            className="add-field-btn"
            color="link"
            onClick={handleAddSelectBox}
            disabled={language.includes("")}
          >
            Add <span className="add-icon">+</span>
          </Button>
        </div>
      </>
    );
  };

  const renderEquipment = () => {
    return (
      <>
        <div className="common-dropdown-stepper">
          <Label className="common-stepper-label">Equipment Provided</Label>
          <Select
            className="common-dropdown common-antd-component"
            value={equipment}
            mode="multiple"
            placeholder="Select all that apply"
            suffixIcon={<CustomArrowIcon />}
            options={allEquipments.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onChange={(val) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                equipment: val,
              }));
            }}
            showSearch={false}
          />
        </div>
      </>
    );
  };

  const renderService = () => {
    return (
      <>
        <div className="common-dropdown-stepper">
          <Label className="common-stepper-label">Services Provided</Label>
          <Select
            className="common-dropdown common-antd-component"
            value={services}
            mode="multiple"
            placeholder="Select all that apply"
            suffixIcon={<CustomArrowIcon />}
            options={allServices.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onChange={(val) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                services: val,
              }));
            }}
            showSearch={false}
          />
        </div>
      </>
    );
  };

  const renderSoftware = () => {
    return (
      <>
        <div className="common-dropdown-stepper">
          <Label className="common-stepper-label">Software Provided</Label>
          <Select
            className="common-dropdown common-antd-component"
            value={software}
            mode="multiple"
            placeholder="Select all that apply"
            suffixIcon={<CustomArrowIcon />}
            options={allSoftwares.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onChange={(val) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                software: val,
              }));
            }}
            showSearch={false}
          />
        </div>
      </>
    );
  };

  const renderTestingTime = () => {
    return (
      <>
        <div className="common-radio-option">
          <Label className="common-stepper-label">Testing Time</Label>
          <Radio.Group
            className="common-single-select"
            onChange={(e) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                testingTime: e.target.value,
              }));
            }}
            value={testingTime}
          >
            <Space className="radio-wrapper" direction="vertical">
              <Radio value={"20-mins"}>20 Min</Radio>
              <Radio value={"30-mins"}>30 Min</Radio>
              {/* <Radio value={"60-mins"}>1 Hour</Radio> */}
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  };

  const renderVaccination = () => {
    return (
      <>
        <div className="common-radio-option">
          <Label className="common-stepper-label">Vaccination Required</Label>
          <Radio.Group
            className="common-single-select"
            onChange={(e) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                isVaccinated: e.target.value,
              }));
            }}
            value={isVaccinated}
          >
            <Space className="radio-wrapper" direction="vertical">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  };

  const handleChange = (info) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      logo: info.file.originFileObj,
    }));
  };

  const handleRemove = () => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      logo: null,
    }));
  };

  const uploadButton = (
    <button className="logo-upload-btn" type="button">
      <span className="btn-upload-icon">
        <img src={uploadIcon} alt="upload Logo" />
      </span>
      <div className="btn-upload-label">Upload Logo</div>
    </button>
  );

  const renderUploadLogo = () => {
    return (
      <>
        <div>
          <Label className="common-stepper-label">Logo</Label>
          <div className="logo-uploader-wrapper">
            <Upload
              listType="picture-circle"
              onChange={handleChange}
              onRemove={handleRemove}
              maxCount={1}
              accept="image/png, image/jpeg, image/jpg"
            >
              {uploadButton}
            </Upload>
            <span className="upload-img-info">
              Image must be no larger than 10 Mb*
            </span>
          </div>
        </div>
      </>
    );
  };

  const handleAddTextbox = () => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      additionalRequirements: [...additionalRequirements, ""],
    }));
  };

  const handleTextboxChange = (index, value) => {
    const updatedRequirements = additionalRequirements.map((item, i) => {
      if (i === index) {
        return value;
      }
      return item;
    });
    setCompanyProfileObj((prev) => ({
      ...prev,
      additionalRequirements: updatedRequirements,
    }));
  };

  const handleRemoveAdditionalRequirement = (arr, idx) => {
    if (idx !== -1) {
      const newArr = [...arr];
      newArr.splice(idx, 1);
      setCompanyProfileObj((prev) => ({
        ...prev,
        additionalRequirements: newArr,
      }));
    }
  };
  const renderAdditionalRequirements = () => {
    return (
      <>
        <div className="add-requirement-field">
          <Label className="common-stepper-label">
            Additional Requirements
          </Label>
          {additionalRequirements?.map((requirement, index) => (
            <div className="add-required-field-wrapper" key={index}>
              {additionalRequirements.length > 1 && (
                <Button
                  className="delete-icon-wrap"
                  color="link"
                  onClick={() =>
                    handleRemoveAdditionalRequirement(
                      additionalRequirements,
                      index
                    )
                  }
                >
                  <img src={deleteIcon} alt="delete icon" />
                </Button>
              )}
              <div className="add-requirement-input-wrap">
                <Input
                  key={index}
                  type="text"
                  className="stepper-input-field"
                  placeholder="Enter Additional Requirements"
                  value={requirement}
                  onChange={(e) => handleTextboxChange(index, e.target.value)}
                  maxLength={250}
                />
              </div>
            </div>
          ))}
        </div>
        {additionalRequirements.length < 3 && (
          <div className="add-other-field">
            <Button
              className="add-field-btn"
              color="link"
              onClick={handleAddTextbox}
            >
              Add <span className="add-icon">+</span>
            </Button>
          </div>
        )}
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps = [
    {
      title: "Company",
      miniTitle: "Company Name",
      content: renderCompany(),
    },
    {
      title: "Store Location",
      miniTitle: "Location",
      content: renderLocation(),
    },
    {
      title: "Company Contact Details",
      miniTitle: "Contact",
      content: renderContact(),
    },
    {
      title: "Languages",
      miniTitle: "Languages",
      content: renderLanguages(),
    },
    {
      title: "Equipment Provided",
      miniTitle: "Equipment",
      content: renderEquipment(),
    },
    {
      title: "Services Provided",
      miniTitle: "Services",
      content: renderService(),
    },
    {
      title: "Software Used",
      miniTitle: "Software",
      content: renderSoftware(),
    },
    {
      title: "Testing Time",
      miniTitle: "Testing",
      content: renderTestingTime(),
    },
    {
      title: "Vaccination",
      miniTitle: "Vaccination",
      content: renderVaccination(),
    },
    {
      title: "Company Logo",
      miniTitle: "Logo",
      content: renderUploadLogo(),
    },
    {
      title: "Additional Requirements",
      miniTitle: "Requirements",
      content: renderAdditionalRequirements(),
    },
    {
      title: "Pricing",
      miniTitle: "Pricing",
      content: renderPricingPlan(),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const skip = () => {
    setCurrent(current + 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const isCurrentStepEmpty = () => {
    switch (current) {
      case 0:
        return !companyName;
      default:
        return false;
    }
  };


  useEffect(() => {
    if (canceledSubscription) {
      const pricingIndex = steps.map(el => el.miniTitle).lastIndexOf("Pricing");
      setCurrent(pricingIndex)
    }
  }, [canceledSubscription, companyProfile, steps])

  return {
    current,
    next,
    prev,
    skip,
    steps,
    items,
    handleClosePaymentForm,
    handleComplete,
    showPaymentForm,
    selectedProduct,
    createCompanyProfile,
    isCurrentStepEmpty,
    canceledSubscription
  };
};

export default useCompanyProfileSetupHook;
