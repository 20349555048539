/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import RoutesName from "variables/route";
import { useAuth } from "context/AuthContext";

function AdminLayout(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  const { companyProfile, userData } = useAuth();
  const { company_profile_setup, dashboard } = RoutesName;
  const navigate = useNavigate();
  let ps;

  useEffect(() => {
    if (mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current, {
        wheelSpeed: 0.5,
        wheelPropagation: true,
        minScrollbarLength: 20,
      });
    }

    return () => {
      if (ps) {
        ps.destroy();
      }
    };
  }, [mainPanel]);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  // const handleActiveClick = (color) => {
  //   setActiveColor(color);
  // };

  // const handleBgClick = (color) => {
  //   setBackgroundColor(color);
  // };

  useEffect(() => {
    if (!companyProfile) {
      const route = !companyProfile ? company_profile_setup : dashboard;
      navigate(route, { replace: true });
    }
  }, [companyProfile, navigate]);

  const canceledSubscription = companyProfile?.pricingPlan?.subscriptionStatus === 'canceled' //|| companyProfile?.pricingPlan?.subscriptionStatus === 'trialing'
  return (
    <div className="wrapper">
      {companyProfile && <DemoNavbar {...props} />}
      {!!companyProfile && !canceledSubscription && (
        <Sidebar
          {...props}
          routes={routes}
          bgColor={backgroundColor}
          activeColor={activeColor}
          userData={userData}
          companyProfile={companyProfile}
        />
      )}

      <div className={!!companyProfile && !canceledSubscription ? "main-panel admin-main-body" : ""}>
        <div className="admin-panel-content" ref={mainPanel}>
          {!companyProfile && <DemoNavbar {...props} />}
          <Routes>
            {routes.map((prop, key) => (
              <Route
                path={prop.path}
                element={
                  <React.Suspense fallback={<div>Loading...</div>}>
                    {prop.component}
                  </React.Suspense>
                }
                key={key}
                exact
              />
            ))}
          </Routes>
          {/* {!!companyProfile && <Footer fluid />} */}
        </div>
      </div>
      {/* <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
      /> */}
    </div>
  );
}

export default AdminLayout;
