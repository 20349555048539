import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Modal, Spin } from "antd";
import { createPortal } from "react-dom"; // React Portal for Light DOM
import { post_api } from "api";
import { toast } from "react-toastify";

const PaymentMethodForm = ({
  visible,
  onClose,
  selectedProduct,
  createCompanyProfile,
  discountCode,
  isVerfiedDiscountCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardElementContainer, setCardElementContainer] = useState(null); // State to hold CardElement container in Light DOM
  const [cardWidth, setCardWidth] = useState("30%"); // Default width for desktop
  const [processingPayment, setProcessingPayment] = useState(false); // Control to show spinner or hide CardElement after payment
  const stripe = useStripe();
  const elements = useElements();

  // Create a Light DOM container for CardElement when component mounts
  useEffect(() => {
    const container = document.createElement("div");
    container.id = "card-element-container";
    document.body.appendChild(container);
    setCardElementContainer(container);

    // Set card width based on screen size
    const updateCardWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setCardWidth("70%"); // Mobile: 60% width
      } else {
        setCardWidth("30%"); // Desktop: 30% width
      }
    };

    // Update card width on initial render and on resize
    updateCardWidth();
    window.addEventListener("resize", updateCardWidth);

    return () => {
      document.body.removeChild(container); // Cleanup when unmounting
      window.removeEventListener("resize", updateCardWidth); // Remove resize listener
    };
  }, []);

  const getStripeByProductId = async (paymentMethodId, productId) => {
    try {
      setLoading(true);
      const { data } = await post_api("stripe/product", {
        productId,
        paymentMethodId,
        couponCode: discountCode || null,
      });
      await createCompanyProfile();
      toast.success("Payment successful!");
      setLoading(false);
      onClose();
      return data;
    } catch (err) {
      console.error("Error during Stripe payment:", err);
      toast.error("Payment failed: " + err.message);
      setLoading(false);
    }
  };

  const handleCreatePaymentMethod = async (event) => {
    event.preventDefault();

    if (!isVerfiedDiscountCode && discountCode) {
      toast.error("Please verify the discount code.");
      return;
    }

    if (!stripe || !elements) {
      toast.error("Stripe.js has not loaded yet.");
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      toast.error("CardElement is not available. Please try again.");
      setLoading(false);
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (result.error) {
      console.error("Failed to create payment method:", result.error.message);
      toast.error(result.error.message);
      setLoading(false);
    } else {
      const paymentMethodId = result.paymentMethod.id;
      const productId = selectedProduct?.productId;

      // After successfully creating payment method, hide the card element
      setProcessingPayment(true); // Show loading spinner, hide CardElement
      await getStripeByProductId(paymentMethodId, productId);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "rgb(0, 64, 43)",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "rgb(0, 64, 43)",
        },
      },
      invalid: {
        color: "rgb(220, 53, 69)",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  return (
    <>
      <Modal
        className="payment-method-popup"
        centered
        open={visible}
        onCancel={!loading ? onClose : null}
        footer={null}
        maskClosable={!loading}
        keyboard={!loading}
      >
        {loading && <Spin size="large" />}
        {!loading && (
          <form onSubmit={handleCreatePaymentMethod} autoComplete="off">
            {/* Placeholder for visual alignment in the modal */}
            <div className="card-placeholder" style={{ height: "60px", marginBottom: "20px" }}></div>
            <div className="payment-btn-wrap">
              <button
                className="btn main-btn-style"
                type="submit"
                disabled={!stripe || loading || processingPayment}
              >
                {processingPayment ? <Spin /> : "Pay Now"}
              </button>
            </div>
          </form>
        )}
      </Modal>

      {/* Render CardElement in Light DOM using React Portal, only when modal is visible */}
      {visible && !processingPayment && cardElementContainer &&
        createPortal(
          <div
            className="card-wrapper"
            style={{
              position: "absolute", // Position it in the modal
              top: "45%", // Adjust this to position inside the modal correctly
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the CardElement visually
              zIndex: 10000, // Ensure it's on top of the modal
              width: cardWidth, // Dynamically set width based on screen size
            }}
          >
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>,
          cardElementContainer
        )}
    </>
  );
};

export default PaymentMethodForm;
