import { Button } from "antd";
import { post_api } from "api";
import { get_api } from "api";
import { useAuth } from "context/AuthContext";
import dayjs from "dayjs";
import { addChatListToUser } from "firebaseConfig";
import { get, startCase } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useLocumListHook = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locumUserData, setLocumUserData] = useState({});
  const [locumUserLoading, setLocumUserLoading] = useState(false);
  const { userData, companyProfile } = useAuth();

  const getAllPostShifts = async () => {
    try {
      setIsLoading(true);
      const { data: locumsData } = await get_api("locum-users");
      setData(locumsData.combinedData);
      setIsLoading(false);
      return locumsData;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPostShifts();
  }, []);

  const getLocumUserById = async (id) => {
    try {
      setLocumUserLoading(true);
      const res = await get_api(`profile/${id}/locum`);
      setLocumUserData(res?.data?.locumUsersProfile);
      setLocumUserLoading(false);
      return true;
    } catch (err) {
      toast.error(err.message);
      setLocumUserLoading(false);
    }
  };

  const createChatRoom = async (locumUserId) => {
    try {
      const { data } = await post_api(`chat/create-chat`, { locumUserId });
      return data;
    } catch (err) {
      console.log("🚀 ~ createChatRoom ~ err:", err.message);
      toast.error(err.message);
    }
  };

  const handleInitiateChat = async (chatRoomData, locumUserId, record) => {
    setIsLoading(true);
    if (!chatRoomData?._id) {
      chatRoomData = await createChatRoom(locumUserId);
    }

    const userChatData = {
      chat_id: chatRoomData._id,
      chat_user_name: `${startCase(
        record?.locumUser?.userId?.first_name
      )} ${startCase(record?.locumUser?.userId?.last_name)}`,
      chat_user_id: locumUserId,
      chat_user_profile_picture: record?.locumUser?.profile_photo,
      chat_time: new Date(),
      is_user_archived: false,
    };

    await addChatListToUser(chatRoomData.businessUserId, userChatData);

    const locumChatData = {
      chat_id: chatRoomData._id,
      chat_user_name: `${startCase(
        userData?.first_name
      )} ${startCase(userData?.last_name)}`,
      chat_user_id: userData?.id,
      chat_user_profile_picture: get(companyProfile, "companyLogo") ? get(companyProfile, "companyLogo") : `${process.env.REACT_APP_S3_URL}+"/default_images/optom_default.png"`,
      chat_time: new Date(),
      is_user_archived: false,
    };

    await addChatListToUser(chatRoomData.locumUserId, locumChatData);
    navigate("/messages", { state: { chatId: chatRoomData._id } });
    setIsLoading(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div
          key={record?.postShiftData._id}
          onClick={() => getLocumUserById(record?.locumUser?.userId?._id)}
          style={{ cursor: "pointer" }}
        >
          <p>{`${record?.locumUser?.userId?.first_name} ${record?.locumUser?.userId?.last_name}`}</p>
        </div>
      ),
      align: "left",
    },
    {
      title: "AHPRA",
      dataIndex: "ahpra",
      key: "ahpra",
      render: (text, record) => (
        <div key={record?.postShiftData._id}>
          <span className="request-name">{`${record?.locumUser?.ahpra}`}</span>
        </div>
      ),
      align: "center",
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div
          className="fields-wrapper"
          key={record?.postShiftData._id}
          style={{ cursor: "pointer" }}
        >
          <p>
            {dayjs(record?.postShiftData?.date)
              .locale("en")
              .format("ddd DD MMM YYYY")}
          </p>
          <p>
            {record?.postShiftData?.start_time} -{" "}
            {record?.postShiftData?.end_time}
          </p>
        </div>
      ),
      align: "center",
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => (
        <div key={record?.postShiftData._id}>
          {record?.postShiftData?.total_hours}
        </div>
      ),
      align: "center",
    },
    {
      title: "Rate",
      dataIndex: "total_rate",
      key: "total_rate",
      render: (text, record) => {
        return (
          <div key={record?.postShiftData._id}>
            $ {record?.postShiftData?.total_rate}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return (
          <div key={record?.postShiftData._id}>
            {startCase(record?.postShiftData?.hourly_rate_type)}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          className="green-tbl-btn"
          size="small"
          key={record?.postShiftData._id}
          onClick={() =>
            handleInitiateChat(
              record?.chatRoomData,
              record?.locumUser?.userId?._id,
              record
            )
          }
        >
          Message
        </Button>
      ),
      align: "right",
    },
  ];
  return { columns, data, isLoading, locumUserData, locumUserLoading };
};
export default useLocumListHook;
