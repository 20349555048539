import React, { useEffect } from 'react';
import { Steps } from "antd";
import { Button, Col, Row } from "reactstrap";
import useCompanyProfileSetupHook from "./useCompanyProfileSetupHook";
import "./CompanyProfileSetup.scss";
import PaymentMethodForm from "./PaymentMethodForm";
import classNames from "classnames";

const { Step } = Steps;

const CompanyProfileSetup = () => {
  const {
    current,
    skip,
    next,
    prev,
    steps,
    items,
    handleClosePaymentForm,
    handleComplete,
    showPaymentForm,
    selectedProduct,
    createCompanyProfile,
    isCurrentStepEmpty,
    canceledSubscription, discountCode, isVerfiedDiscountCode
  } = useCompanyProfileSetupHook();

  const companySetUpWrapper = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (windowWidth < 768) {

      //height and space of navbar
      const navBarSpace = document.querySelector('.inner-main-navbar');
      const navBarSpacePadTop = navBarSpace ? parseFloat(getComputedStyle(navBarSpace).paddingTop) : 0;
      const navBarSpacePadBot = navBarSpace ? parseFloat(getComputedStyle(navBarSpace).paddingBottom) : 0;
      const navBarSpacetotal = navBarSpacePadTop + navBarSpacePadBot;

      //company setup space
      const companySetupSpace = document.querySelector('.company-setup-wrapper');
      const companySetupPadTop = companySetupSpace ? parseFloat(getComputedStyle(companySetupSpace).paddingTop) : 0;
      const companySetupPadBot = companySetupSpace ? parseFloat(getComputedStyle(companySetupSpace).paddingBottom) : 0;
      const companySetupPadding = companySetupPadTop + companySetupPadBot;

      //setup margin
      const setupSpace = document.querySelector('.setup-stepper-sec');
      const setupSpaceMarTop = setupSpace ? parseFloat(getComputedStyle(setupSpace).marginTop) : 0;

      //profile stepper header
      const profileStepHeader = document.querySelector('.profile-stepper-header');
      const profileStepTotalHeight = profileStepHeader ? profileStepHeader.offsetHeight : 0;

      //stepper footer
      const stepperFooterSpace = document.querySelector('.stepper-footer');
      const stepperFooterHeight = stepperFooterSpace ? stepperFooterSpace.offsetHeight : 0;
      const stepperFooterTotal = stepperFooterHeight;
      
      const calculatedHeight = windowHeight - navBarSpacetotal - companySetupPadding - setupSpaceMarTop - profileStepTotalHeight - stepperFooterTotal - 30;

      // Apply the calculated height if the .form-wrap element exists
      const formWrapElement = document.querySelector('.stepper-content-section');
      if (formWrapElement) {
        formWrapElement.style.height = `${calculatedHeight}px`;
      }
    }
  };

  useEffect(() => {
    companySetUpWrapper();
    window.addEventListener('resize', companySetUpWrapper);
  
    // Add keydown listener
    const handleEnterKey = (event) => {
      if (event.keyCode === 13 && !isCurrentStepEmpty()) { // 13 is the Enter key
        event.preventDefault(); // Prevent the default Enter key behavior
        next(); // Call the next function to go to the next step
      }
    };
  
    document.addEventListener('keydown', handleEnterKey);
  
    return () => {
      window.removeEventListener('resize', companySetUpWrapper);
      document.removeEventListener('keydown', handleEnterKey); // Clean up the event listener
    };
  }, [isCurrentStepEmpty, next]);

  return (
    <div id="companystepper" className="content company-setup-wrapper">
      <div className="container">
        <Row>
          <Col xs={12}>
            <div className="setup-stepper-sec">
              <div id="stepperhead" className="profile-stepper-header">
                <h2 className="stepper-title">{steps[current].title}</h2>
                <div className="stepper-header-details">
                  <h3 className="stepper-sub-title">
                    {steps[current].miniTitle}
                  </h3>
                  {!["Company Name", "Pricing"].includes(
                    steps[current].miniTitle
                  ) && (
                    <Button
                      className="stepper-skip-btn"
                      color="link"
                      onClick={skip}
                      disabled={
                        current === steps.length - 1 || isCurrentStepEmpty()
                      }
                    >
                      Skip For Now
                    </Button>
                  )}
                </div>
                <Steps current={current} progressDot className="custom-steps">
                  {items.map((item) => (
                    <Step key={item.key} />
                  ))}
                </Steps>
              </div>
              <div
                id="stepperbody"
                className={classNames("stepper-main-body", {
                  "pricing-step": steps[current].miniTitle === "Pricing",
                  "language-step": steps[current].miniTitle === "Languages",
                  "requirements-step": steps[current].miniTitle === "Requirements",
                })}
              >
                <div className="stepper-content-section">
                  <PaymentMethodForm
                    discountCode={discountCode}
                    isVerfiedDiscountCode={isVerfiedDiscountCode}
                    visible={showPaymentForm}
                    onClose={handleClosePaymentForm}
                    selectedProduct={selectedProduct}
                    createCompanyProfile={createCompanyProfile}
                  />
                  <>{steps[current].content}</>
                </div>
                <div className="stepper-footer">
                  {current > 0 && (
                    <Button className="alt-btn-stye" onClick={prev}
                      disabled={canceledSubscription}
                    >
                      Previous
                    </Button>
                  )}
                  {current < steps.length - 1 && (
                    <Button
                      className="main-btn-stye"
                      onClick={next}
                      disabled={isCurrentStepEmpty()}
                    >
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button className="main-btn-stye" onClick={handleComplete}>
                      Complete
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyProfileSetup;
