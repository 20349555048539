import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Modal, Spin } from "antd";
import { post_api } from "api";
import { toast } from "react-toastify";

const PaymentMethodForm = ({
  visible,
  onClose,
  selectedProduct,
  createCompanyProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const getStripeByRpoductId = async (paymentMethodId, productId) => {
    try {
      setLoading(true);
      const { data } = await post_api("stripe/product", {
        productId,
        paymentMethodId,
      });
      await createCompanyProfile();
      setLoading(false);
      onClose();
      return data;
    } catch (err) {
      console.log("🚀 ~ getStripeByRpoductId ~ err:", err);
      toast.error(err.message);
      setLoading(false);
    }
  };
  const handleCreatePaymentMethod = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      console.error("Failed to create payment method:", result.error.message);
      setLoading(false);
    } else {
      const paymentMethodId = result?.paymentMethod?.id;
      const productId = selectedProduct?.productId;
      await getStripeByRpoductId(paymentMethodId, productId);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "rgb(0, 64, 43)",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "rgb(0, 64, 43)",
        },
        // Remove border styles from here
      },
      invalid: {
        color: "rgb(220, 53, 69)",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const containerStyle = {
    height: "60px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
  };

  return (
    <Modal
      className={"payment-method-popup"}
      centered
      open={visible}
      onCancel={loading ? null : onClose}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      {loading && <Spin fullscreen />}
      <form onSubmit={handleCreatePaymentMethod} autoComplete="off">
        <div className="card-wrapper" style={containerStyle}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div className="payment-btn-wrap">
          <button
            className="btn main-btn-stye"
            type="submit"
            disabled={!stripe}
          >
            Pay Now
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PaymentMethodForm;
