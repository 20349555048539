import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  setDoc,
  onSnapshot,
  deleteDoc,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { get, startCase } from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const db = getFirestore(app);
const storage = getStorage(app);

const requestToken = () => {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        // console.log(
        //   "Service Worker registration successful, scope:",
        //   registration.scope
        // );
        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAP_ID,
          serviceWorkerRegistration: registration, // Ensure registration is used in the same promise chain
        });
      })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          return requestNotificationPermission().then((permission) => {
            if (permission === "granted") {
              // Handle this case: Might need to get registration again or assume it's still valid
              return navigator.serviceWorker.ready.then((registration) => {
                return getToken(messaging, {
                  vapidKey: process.env.REACT_APP_VAP_ID,
                  serviceWorkerRegistration: registration,
                });
              });
            } else {
              throw new Error("Permission denied");
            }
          });
        }
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });
  } else {
    console.error("Service workers are not supported by this browser.");
    throw new Error("Service workers are not supported by this browser.");
  }
};

function requestNotificationPermission() {
  // return Notification.requestPermission();
  if ("Notification" in window) {
    return Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          return navigator.serviceWorker.getRegistration().then((reg) => {
            reg.showNotification("Notification: You are subscribed!");
          });
        }
      })
      .catch((error) => {
        console.error("Failed to get notification permission:", error);
        return false;
      });
  } else {
    console.warn(
      "This browser does not support desktop notification. Try a different browser for a better experience."
    );
    // Additional code to handle fallbacks
    return false;
  }
}

const handleSignInWithGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  // provider.addScope("https://www.googleapis.com/auth/cloud-platform.read-only");
  // provider.addScope("https://www.googleapis.com/auth/devstorage.read_only");
  // provider.addScope("https://www.googleapis.com/auth/pubsub");

  // Add minimal scopes, e.g., profile and email
  provider.addScope('profile');
  provider.addScope('email');

  try {
    const result = await signInWithPopup(auth, provider);

    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;
    console.log("🚀 ~ Google Login User Info:", user);

    // You might want to do something with the token or user here
    return user;
  } catch (error) {
    console.error("Google Sign-in Error:", error);
  }
};

const getFirebaseUser = async (userData, userProfile) => {
  try {
    const q = query(
      collection(db, "users"),
      where("user_id", "==", userData.id)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      const userDocRef = await addDoc(collection(db, "users"), {
        user_full_name: startCase(userProfile?.companyName),
        profile_picture: get(userProfile, "companyLogo", ""),
        status: "online",
        user_id: userData.id,
      });
      const userDoc = await getDoc(userDocRef);
      return userDoc.data();
    } else {
      const updatedRecord = {
        status: "online",
        user_full_name: startCase(userProfile?.companyName),
        profile_picture: get(userProfile, "companyLogo", ""),
      };
      await updateFirebaseUserByUserId(userData.id, updatedRecord);
      return querySnapshot.docs[0].data();
    }
  } catch (error) {
    console.error("Error signing up:", error.message);
  }
};

const updateFirebaseUserByUserId = async (user_id, updatedData) => {
  try {
    const q = query(collection(db, "users"), where("user_id", "==", user_id));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userDocRef = doc(db, "users", userDoc.id);

      await updateDoc(userDocRef, updatedData);
    } else {
      console.log("User not found!");
    }
  } catch (error) {
    console.error("Error updating user:", error.message);
  }
};

const addChatListToUser = async (businessUserId, chatListData) => {
  try {
    const q = query(
      collection(db, "users"),
      where("user_id", "==", businessUserId)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userDocRef = userDoc.ref;
      const chatListRef = collection(userDocRef, "user_chat_list");
      const chatQuery = query(
        chatListRef,
        where("chat_id", "==", chatListData.chat_id)
      );
      const chatSnapshot = await getDocs(chatQuery);

      if (chatSnapshot.empty) {
        await addDoc(chatListRef, chatListData);
      } else {
        const existingChatDoc = chatSnapshot.docs[0];
        const chatDocRef = existingChatDoc.ref;

        await setDoc(chatDocRef, chatListData);
      }
    } else {
      console.log("No user found with the specified user_id.");
    }
  } catch (error) {
    console.error("Error adding/updating chat list item:", error.message);
  }
};

const firestoreTimestampToDate = (timestamp) => {
  if (!timestamp) return null;
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
};

const getUserChatList = async (businessUserId, isArchived) => {
  try {
    const userQuery = query(
      collection(db, "users"),
      where("user_id", "==", businessUserId)
    );
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      const userDoc = userQuerySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      const chatListRef = collection(userDocRef, "user_chat_list");

      let chatListQuery = query(
        chatListRef,
        where("is_user_archived", "==", isArchived)
      );

      const chatListSnapshot = await getDocs(chatListQuery);
      const chatListData = await Promise.all(
        chatListSnapshot.docs.map(async (doc) => {
          const chatData = doc.data();
          const chatId = chatData.chat_id;
          const messagesRef = collection(db, "chats", chatId, "messages");
          const unreadMessagesQuery = query(
            messagesRef,
            where("read", "==", false),
            where("receiver_id", "==", businessUserId)
          );
          const unreadMessagesSnapshot = await getDocs(unreadMessagesQuery);
          const unreadCount = unreadMessagesSnapshot.size;

          const allMessagesQuery = query(messagesRef, orderBy("time", "desc"));
          const querySnapshot = await getDocs(allMessagesQuery);
          const allMessages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // .sort((a, b) => b.time - a.time);

          // Get the latest message timestamp
          const latestMessageTime = allMessages[0]?.time || null;
          const relativeTime = latestMessageTime
            ? dayjs(firestoreTimestampToDate(latestMessageTime)).fromNow()
            : "No messages";

          // Fetch the user's online/offline status from the users table
          const chatUserQuery = query(
            collection(db, "users"),
            where("user_id", "==", chatData.chat_user_id)
          );
          const chatUserSnapshot = await getDocs(chatUserQuery);
          const chatUser = chatUserSnapshot?.docs[0]?.data()
            ? chatUserSnapshot.docs[0].data()
            : { status: "offline" };

          return {
            ...chatData,
            unreadCount,
            relativeTime,
            relativeTimeStamp: latestMessageTime,
            userStatus: chatUser.status,
          };
        })
      );

      const archivedChatCountQuery = query(
        chatListRef,
        where("is_user_archived", "==", !isArchived)
      );
      const archivedChatCountSnapshot = await getDocs(archivedChatCountQuery);
      const archiveRecordCount = archivedChatCountSnapshot.size;

      chatListData.sort((a, b) => {
        const aDate = a.relativeTimeStamp
          ? firestoreTimestampToDate(a.relativeTimeStamp)
          : new Date(0);
        const bDate = b.relativeTimeStamp
          ? firestoreTimestampToDate(b.relativeTimeStamp)
          : new Date(0);
        return bDate - aDate;
      });
      return {
        chatList: chatListData,
        archiveRecordCount,
      };
    } else {
      console.log("No user found with the specified user_id.");
      return {
        chatList: [],
        archiveRecordCount: 0,
      };
    }
  } catch (error) {
    console.error("Error retrieving user chat list:", error.message);
    return {
      chatList: [],
      archiveRecordCount: 0,
    };
  }
};

const listenForUserChatListUpdates = (businessUserId, callback, isArchived) => {
  try {
    const userQuery = query(
      collection(db, "users"),
      where("user_id", "==", businessUserId)
    );

    // Use onSnapshot to listen for real-time updates
    const unsubscribe = onSnapshot(userQuery, (userQuerySnapshot) => {
      if (!userQuerySnapshot.empty) {
        const userDoc = userQuerySnapshot.docs[0];
        const userDocRef = userDoc.ref;

        const chatListRef = collection(userDocRef, "user_chat_list");

        const chatListQuery = query(
          chatListRef,
          where("is_user_archived", "==", isArchived)
        );

        onSnapshot(chatListQuery, async (chatListSnapshot) => {
          const newChatList = await Promise.all(
            chatListSnapshot.docs.map(async (doc) => {
              const chatData = doc.data();
              const chatId = chatData.chat_id;
              const messagesRef = collection(db, "chats", chatId, "messages");
              const unreadMessagesQuery = query(
                messagesRef,
                where("read", "==", false),
                where("receiver_id", "==", businessUserId)
              );

              const unreadMessagesSnapshot = await getDocs(unreadMessagesQuery);
              const unreadCount = unreadMessagesSnapshot.size;

              const allMessagesQuery = query(
                messagesRef,
                orderBy("time", "desc")
              );
              const querySnapshot = await getDocs(allMessagesQuery);
              const allMessages = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              // Get the latest message timestamp
              const latestMessageTime = allMessages[0]?.time || null;
              const relativeTime = latestMessageTime
                ? dayjs(firestoreTimestampToDate(latestMessageTime)).fromNow()
                : "No messages";

              // Fetch the user's online/offline status from the users table
              const chatUserQuery = query(
                collection(db, "users"),
                where("user_id", "==", chatData.chat_user_id)
              );
              const chatUserSnapshot = await getDocs(chatUserQuery);
              const chatUser = chatUserSnapshot?.docs[0]?.data()
                ? chatUserSnapshot.docs[0].data()
                : { status: "offline" };

              return {
                ...chatData,
                unreadCount,
                relativeTime,
                relativeTimeStamp: latestMessageTime,
                userStatus: chatUser.status,
              };
            })
          );

          const archivedChatListQuery = query(
            chatListRef,
            where("is_user_archived", "==", true)
          );
          const archivedChatListSnapshot = await getDocs(archivedChatListQuery);
          const archivedCount = archivedChatListSnapshot.size;

          newChatList.sort((a, b) => {
            const aDate = a.relativeTimeStamp
              ? firestoreTimestampToDate(a.relativeTimeStamp)
              : new Date(0);
            const bDate = b.relativeTimeStamp
              ? firestoreTimestampToDate(b.relativeTimeStamp)
              : new Date(0);
            return bDate - aDate;
          });

          callback({
            chatList: newChatList,
            archivedCount,
          });
        });
      } else {
        console.log("No user found with the specified user_id.");
        callback({
          chatList: [],
          archivedCount: 0,
        });
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error listening for user chat list updates:", error.message);
    return () => {};
  }
};

const addChatsRecord = async (chatId, businessUserId) => {
  try {
    const chatDocRef = doc(db, "chats", chatId);
    await setDoc(chatDocRef, {});

    const batch = writeBatch(db);
    const messagesRef = collection(db, "chats", chatId, "messages");
    const unreadMessagesQuery = query(
      messagesRef,
      where("read", "==", false),
      where("receiver_id", "==", businessUserId)
    );

    // Fetch all unread messages
    const unreadMessagesSnapshot = await getDocs(unreadMessagesQuery);

    // Add update operations to the batch
    unreadMessagesSnapshot.docs.forEach((docSnapshot) => {
      const messageDocRef = doc(
        db,
        "chats",
        chatId,
        "messages",
        docSnapshot.id
      );
      batch.update(messageDocRef, { read: true });
    });

    // Commit the batch
    await batch.commit();

    return chatDocRef.id;
  } catch (error) {
    console.error("Error creating empty chat:", error.message);
    throw new Error("Failed to create empty chat");
  }
};

const getMessagesForChat = async (chatId) => {
  try {
    const chatDocRef = doc(db, "chats", chatId);
    const messagesCollectionRef = collection(chatDocRef, "messages");

    const messagesQuery = query(messagesCollectionRef, orderBy("time", "asc"));

    const querySnapshot = await getDocs(messagesQuery);

    const messages = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .sort((a, b) => a.time - b.time);

    return messages;
  } catch (error) {
    console.error("Error getting messages for chat:", error.message);
    throw new Error("Failed to get messages for chat");
  }
};

const addMessageToChat = async (chatId, messageData) => {
  try {
    const chatDocRef = doc(db, "chats", chatId);
    const messageDocRef = await addDoc(
      collection(chatDocRef, "messages"),
      messageData
    );

    return { messageDocId: messageDocRef.id };
  } catch (error) {
    console.error("Error adding message to chat:", error.message);
    throw new Error("Failed to add message to chat");
  }
};

const listenForNewMessages = (chatId, callback) => {
  try {
    const chatDocRef = doc(db, "chats", chatId);
    const messagesCollectionRef = collection(chatDocRef, "messages");

    const unsubscribe = onSnapshot(messagesCollectionRef, (snapshot) => {
      const messages = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => a.time - b.time);

      callback(messages);
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error listening for new messages:", error.message);
    return () => {};
  }
};

const getChatListItemByChatId = async (businessUserId, chatId) => {
  try {
    const userQuery = query(
      collection(db, "users"),
      where("user_id", "==", businessUserId)
    );
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      const userDoc = userQuerySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      const chatListRef = collection(userDocRef, "user_chat_list");

      const chatQuery = query(chatListRef, where("chat_id", "==", chatId));
      const chatQuerySnapshot = await getDocs(chatQuery);

      if (!chatQuerySnapshot.empty) {
        const chatDoc = chatQuerySnapshot.docs[0];
        const chatData = chatDoc.data();

        return chatData;
      } else {
        console.log("No chat item found with the specified chat_id.");
        return null;
      }
    } else {
      console.log("No user found with the specified user_id.");
      return null;
    }
  } catch (error) {
    console.error("Error retrieving chat list item:", error.message);
    return null;
  }
};

const deleteUserFromUserChatList = async (businessUserId, chatId) => {
  try {
    const userQuery = query(
      collection(db, "users"),
      where("user_id", "==", businessUserId)
    );
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      const userDoc = userQuerySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      const chatListRef = collection(userDocRef, "user_chat_list");

      const chatQuery = query(chatListRef, where("chat_id", "==", chatId));
      const chatQuerySnapshot = await getDocs(chatQuery);

      if (!chatQuerySnapshot.empty) {
        const chatDoc = chatQuerySnapshot.docs[0];
        const chatDocRef = chatDoc.ref;

        await deleteDoc(chatDocRef);
      } else {
        console.log("No chat item found with the specified chat_id.");
      }
    } else {
      console.log("No user found with the specified user_id.");
    }
  } catch (error) {
    console.error("Error deleting chat list item:", error.message);
  }
};

const moveChatToArchive = async (businessUserId, chatId, is_user_archived) => {
  try {
    const userQuery = query(
      collection(db, "users"),
      where("user_id", "==", businessUserId)
    );
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      const userDoc = userQuerySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      const chatListRef = collection(userDocRef, "user_chat_list");
      const chatQuery = query(chatListRef, where("chat_id", "==", chatId));
      const chatQuerySnapshot = await getDocs(chatQuery);

      if (!chatQuerySnapshot.empty) {
        const chatDoc = chatQuerySnapshot.docs[0];
        const chatDocRef = chatDoc.ref;

        await updateDoc(chatDocRef, {
          is_user_archived,
        });
      } else {
        console.log("No chat item found with the specified chat_id.");
      }
    } else {
      console.log("No user found with the specified user_id.");
    }
  } catch (error) {
    console.error("Error moving chat item to archive:", error.message);
  }
};

export {
  messaging,
  getToken,
  requestToken,
  handleSignInWithGoogle,
  getFirebaseUser,
  updateFirebaseUserByUserId,
  addChatListToUser,
  getUserChatList,
  listenForUserChatListUpdates,
  addChatsRecord,
  addMessageToChat,
  getMessagesForChat,
  listenForNewMessages,
  getChatListItemByChatId,
  deleteUserFromUserChatList,
  moveChatToArchive,
  storage,
};
